<template>
  <component :is="companyData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="companyData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching company data
      </h4>
      <div class="alert-body">
        No company found with this company id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'company-list'}"
        >
          Company List
        </b-link>
        for other companies.
      </div>
    </b-alert>
    <company-edit-tab-account
      v-if="!loading"
      :company-data="companyData"
      :country-list="countries"
      :currency-list="currencyList"
    />
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import CompanyEditTabAccount from './CompanyEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    CompanyEditTabAccount,
  },
  data() {
    return {
      companyData: null,
      countries: [],
      defaultIso: null,
      loading: false,
      currencyList: null,
    }
  },
  async created() {
    try {
      this.loading = true
      const { data: { data, options } } = await this.$http.get('/company/profile')

      this.companyData = {
        ...data,
        billing_address1: data.billing.address1,
        billing_address2: data.billing.address2,
        billing_city: data.billing.city,
        billing_state: data.billing.state,
        billing_postcode: data.billing.postcode,
        billing_country: data.billing.country,

        shipping_address1: data.shipping.address1,
        shipping_address2: data.shipping.address2,
        shipping_city: data.shipping.city,
        shipping_state: data.shipping.state,
        shipping_postcode: data.shipping.postcode,
        shipping_country: data.shipping.country,
      }
      this.countries = options.countries.data
      this.currencyList = options.currencies.data
      this.loading = false
    } catch (error) {
      console.log('error :>> ', error)
    }
  },
}
</script>

<style>

</style>
