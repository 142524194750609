<template>
  <div>
    <!-- form -->
    <validation-observer
      ref="registerFormCompany"
    >
      <b-form
        class="auth-register-form"
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col md="12">
            <div class="section-block withMaxHeight mb-4">

              <div class="section-block__title">
                <feather-icon
                  icon="UserIcon"
                  width="24"
                  height="24"
                />
                <h2>Update Company Info</h2>
              </div>

              <div
                v-if="!loading"
              >
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Company Name"
                    label-class="label-class"
                    label-for="register-company-name"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Company Name"
                      vid="name"
                      rules="max:255|required"
                    >
                      <b-form-input
                        id="register-company-name"
                        v-model="form.name"
                        name="register-company-name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Company Name"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Company Registered Number"
                    label-class="label-class"
                    label-for="register-company-number"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Company Registered Number"
                      vid="registered_number"
                      rules="max:255"
                    >
                      <b-form-input
                        id="register-company-number"
                        v-model="form.registered_number"
                        name="register-company-number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Company Registered Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="VAT ID"
                    label-class="label-class"
                    label-for="register-company-vat"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="VAT ID"
                      vid="vat_id"
                      rules="max:255"
                    >
                      <b-form-input
                        id="register-company-vat"
                        v-model="form.vat_id"
                        name="register-company-vat"
                        :state="errors.length > 0 ? false:null"
                        placeholder="VAT ID"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Currency"
                    label-for="register-currency"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Currency"
                      vid="currency"
                      rules="max:255"
                    >

                      <v-select
                        v-model="form.currency"
                        :options="currencyList"
                        placeholder="Select currency"
                        label="name"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Telephone"
                  label-for="register-company-phone_number"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Telephone"
                    vid="phone_number"
                    rules="numeric|min:5|max:15|required"
                  >
                    <vue-tel-input
                      id="register-company-phone_number"
                      ref="companyPhoneNumber"
                      v-model="form.phone_number"
                      class="form-control pl-0"
                      name="register-company-phone_number"
                      :preferred-countries="['GB']"
                      :default-country="phoneISO"
                      :auto-default-country="false"
                      :auto-format="false"
                      :dropdown-options="{
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showDialCodeInList: true
                      }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Email"
                  label-for="register-company-email"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email|max:255"
                  >
                    <b-form-input
                      id="register-company-email"
                      v-model="form.email"
                      name="register-company-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="company@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Web"
                  label-for="register-company-web"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Web"
                    vid="web"
                    rules="max:255|url"
                  >
                    <b-form-input
                      id="register-company-web"
                      v-model="form.web"
                      name="register-company-web"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Type"
                    label-for="register-company-email"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      vid="type"
                      rules="max:255"
                    >
                      <b-form-input
                        id="register-company-role"
                        v-model="companyData.type.name"
                        name="register-company-role"
                        :state="errors.length > 0 ? false : null"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Billing Terms"
                    label-for="register-company-email"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Billing Terms"
                      vid="billingTerm"
                      rules="max:255"
                    >
                      <b-form-input
                        id="register-company-billingTerm"
                        v-model="companyData.billingTerm.name"
                        name="register-company-billingTerm"
                        :state="errors.length > 0 ? false : null"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <p>Billing address:</p>
                <div class="px-50">

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Address Line 1*"
                      label-for="register-billing-address1"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address Line 1"
                        vid="billing_address1"
                        rules="max:255|required"
                      >
                        <b-form-input
                          id="register-billing-address1"
                          v-model="form.billing_address1"
                          name="register-billing-address1"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address Line 1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- address2 -->
                    <b-form-group
                      label="Address Line 2"
                      label-for="register-billing-address2"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address Line 2"
                        vid="billing_address2"
                        rules="max:255"
                      >
                        <b-form-input
                          id="register-billing-address2"
                          v-model="form.billing_address2"
                          name="register-billing-address2"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address Line 2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!-- Country -->
                  <b-form-group
                    label="Country*"
                    label-for="register-billing-country"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      vid="billing_country_id"
                      rules="max:255|required"
                    >

                      <v-select
                        v-model="form.billing_country_id"
                        :options="countryList"
                        :reduce="country_id => country_id.id"
                        placeholder="Select country"
                        label="name"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- region -->
                  <b-form-group
                    label="Region/State*"
                    label-for="register-billing-region"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Region/State"
                      vid="billing_state"
                      rules="max:255"
                    >
                      <b-form-input
                        id="register-billing-region"
                        v-model="form.billing_state"
                        name="register-billing-region"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Region/State"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <div
                    class="neighbouring-form-container"
                  >
                    <!-- city -->
                    <b-form-group
                      label="Town/City*"
                      label-for="register-billing-city"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Town/City"
                        vid="billing_city"
                        rules="required|max:255"
                      >
                        <b-form-input
                          id="register-billing-city"
                          v-model="form.billing_city"
                          name="register-billing-city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Town/City"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- postcode -->
                    <b-form-group
                      label="Postcode/Zip Code*"
                      label-for="register-billing-postcode"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Postcode/Zip Code"
                        vid="billing_postcode"
                        rules="max:255|required"
                      >
                        <b-form-input
                          id="register-billing-postcode"
                          v-model="form.billing_postcode"
                          name="register-billing-postcode"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Postcode/Zip Code"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group>
                  <b-form-checkbox
                    id="isSameAddress"
                    v-model="isSameAddress"
                    name="checkbox-1"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Shipping address same as billing
                  </b-form-checkbox>
                </b-form-group>
                <p v-if="!isSameAddress">
                  Shipping address:
                </p>
                <div
                  v-if="!isSameAddress"
                  class="px-50"
                >
                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Address Line 1*"
                      label-for="register-shipping-address1"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address Line 1"
                        vid="shipping_address1"
                        rules="max:255|required"
                      >
                        <b-form-input
                          id="register-shipping-address1"
                          v-model="form.shipping_address1"
                          name="register-shipping-address1"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address Line 1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- address2 -->
                    <b-form-group
                      label="Address Line 2"
                      label-for="register-shipping-address2"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address Line 2"
                        vid="shipping_address1"
                        rules="max:255"
                      >
                        <b-form-input
                          id="register-shipping-address2"
                          v-model="form.shipping_address2"
                          name="register-shipping-address2"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address Line 2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!-- Country -->
                  <b-form-group
                    label="Country*"
                    label-for="register-shipping-country"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      vid="shipping_country_id"
                      rules="max:255"
                    >

                      <v-select
                        v-model="form.shipping_country_id"
                        :options="countryList"
                        :reduce="country_id => country_id.id"
                        placeholder="Select country"
                        label="name"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- region -->
                  <b-form-group
                    label="Region/State*"
                    label-for="register-shipping-region"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Region/State"
                      vid="shipping_state"
                      rules="max:255"
                    >
                      <b-form-input
                        id="register-shipping-region"
                        v-model="form.shipping_state"
                        name="register-shipping-region"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Region/State"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <div
                    class="neighbouring-form-container"
                  >
                    <!-- city -->
                    <b-form-group
                      label="Town/City*"
                      label-for="register-shipping-city"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Town/City"
                        vid="shipping_city"
                        rules="required|max:255"
                      >
                        <b-form-input
                          id="register-shipping-city"
                          v-model="form.shipping_city"
                          name="register-shipping-city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Town/City"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- postcode -->
                    <b-form-group
                      label="Postcode/Zip Code*"
                      label-for="register-shipping-postcode"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Postcode/Zip Code"
                        vid="shipping_postcode"
                        rules="max:255|required"
                      >
                        <b-form-input
                          id="register-shipping-postcode"
                          v-model="form.shipping_postcode"
                          name="register-shipping-postcode"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Postcode/Zip Code"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-button
          variant="primary"
          :disabled="isSavingActionInProgress"
          block
          type="submit"
        >
          Update company profile
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  required, confirmed, email, min, max, numeric,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const VueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
// eslint-disable-next-line no-shadow
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
    vSelect,

    VueTelInput,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
    countryList: {
      type: Array,
      required: true,
    },
    currencyList: {
      type: Array,
      required: true,
    },
  },
  data() {
    const form = {
      ...this.companyData,
      billing_country_id: this.companyData.billing.country ? this.companyData.billing.country.id : null,
      shipping_country_id: this.companyData.shipping.country ? this.companyData.shipping.country.id : null,
    }
    delete form.shippingCountry
    delete form.billingCountry
    return {
      // validation
      required,
      email,
      confirmed,
      min,
      max,
      numeric,
      form,
      isSameAddress: 0,
      isSavingActionInProgress: false,
    }
  },
  setup(props) {
    return {
      phoneISO: props.companyData.phone_country_iso,
      avatarText,
    }
  },
  mounted() {
    if (!this.phoneISO) {
      setTimeout(() => {
        this.$refs.companyPhoneNumber.choose(this.defaultIso ? this.defaultIso : 'GB')
      }, 500)
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.registerFormCompany.validate()
      if (valid) {
        this.isSavingActionInProgress = true
        let payload = {
          ...this.form,
        }
        if (this.isSameAddress) {
          payload = {
            ...payload,
            shipping_address1: this.form.billing_address1,
            shipping_address2: this.form.billing_address2,
            shipping_country_id: this.form.billing_country_id,
            shipping_postcode: this.form.billing_postcode,
            shipping_state: this.form.billing_state,
            shipping_city: this.form.billing_city,
          }
        }
        if (payload.currency) {
          payload.currency = payload.currency.id
        } else {
          payload.currency = 'pound-sterling'
        }
        if (this.form.phone_number) {
          payload = {
            ...payload,
            ...{ phone_number: this.form.phone_number },
            ...{ phone_country_iso: this.$refs.companyPhoneNumber.phoneObject.country.iso2 },
            ...{ phone_country_code: this.$refs.companyPhoneNumber.phoneObject.country.dialCode },
          }
        }
        try {
          await this.$http.put('/company/profile', {
            ...payload,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile updated!',
              icon: 'BellIcon',
              variant: 'success',
            },
          }, { timeout: false })

          this.$router.push({ name: 'company-profile' })
        } catch (error) {
          const { status } = error.response
          if (status === 404) {
            this.$router.push({ name: 'error-404' })
          }
          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.registerFormCompany.setErrors(fields)
          }
        } finally {
          this.isSavingActionInProgress = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
