var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"registerFormCompany"},[_c('b-form',{staticClass:"auth-register-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"section-block withMaxHeight mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"UserIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Update Company Info")])],1),(!_vm.loading)?_c('div',[_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Company Name","label-class":"label-class","label-for":"register-company-name"}},[_c('validation-provider',{attrs:{"name":"Company Name","vid":"name","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company-name","name":"register-company-name","state":errors.length > 0 ? false:null,"placeholder":"Company Name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,642771545)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Company Registered Number","label-class":"label-class","label-for":"register-company-number"}},[_c('validation-provider',{attrs:{"name":"Company Registered Number","vid":"registered_number","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company-number","name":"register-company-number","state":errors.length > 0 ? false:null,"placeholder":"Company Registered Number"},model:{value:(_vm.form.registered_number),callback:function ($$v) {_vm.$set(_vm.form, "registered_number", $$v)},expression:"form.registered_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1183624377)})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"VAT ID","label-class":"label-class","label-for":"register-company-vat"}},[_c('validation-provider',{attrs:{"name":"VAT ID","vid":"vat_id","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company-vat","name":"register-company-vat","state":errors.length > 0 ? false:null,"placeholder":"VAT ID"},model:{value:(_vm.form.vat_id),callback:function ($$v) {_vm.$set(_vm.form, "vat_id", $$v)},expression:"form.vat_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1588493662)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Currency","label-for":"register-currency","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Currency","vid":"currency","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.currencyList,"placeholder":"Select currency","label":"name"},model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1812615379)})],1)],1),_c('b-form-group',{attrs:{"label":"Telephone","label-for":"register-company-phone_number","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Telephone","vid":"phone_number","rules":"numeric|min:5|max:15|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{ref:"companyPhoneNumber",staticClass:"form-control pl-0",attrs:{"id":"register-company-phone_number","name":"register-company-phone_number","preferred-countries":['GB'],"default-country":_vm.phoneISO,"auto-default-country":false,"auto-format":false,"dropdown-options":{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2926342663)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-company-email","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company-email","name":"register-company-email","state":errors.length > 0 ? false : null,"placeholder":"company@example.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1967308557)})],1),_c('b-form-group',{attrs:{"label":"Web","label-for":"register-company-web","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Web","vid":"web","rules":"max:255|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company-web","name":"register-company-web","state":errors.length > 0 ? false : null,"placeholder":"https://"},model:{value:(_vm.form.web),callback:function ($$v) {_vm.$set(_vm.form, "web", $$v)},expression:"form.web"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2860663680)})],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Type","label-for":"register-company-email","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Type","vid":"type","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company-role","name":"register-company-role","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.companyData.type.name),callback:function ($$v) {_vm.$set(_vm.companyData.type, "name", $$v)},expression:"companyData.type.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2773265090)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Billing Terms","label-for":"register-company-email","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Billing Terms","vid":"billingTerm","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company-billingTerm","name":"register-company-billingTerm","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.companyData.billingTerm.name),callback:function ($$v) {_vm.$set(_vm.companyData.billingTerm, "name", $$v)},expression:"companyData.billingTerm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2960046975)})],1)],1),_c('p',[_vm._v("Billing address:")]),_c('div',{staticClass:"px-50"},[_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Address Line 1*","label-for":"register-billing-address1","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"billing_address1","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-billing-address1","name":"register-billing-address1","state":errors.length > 0 ? false:null,"placeholder":"Address Line 1"},model:{value:(_vm.form.billing_address1),callback:function ($$v) {_vm.$set(_vm.form, "billing_address1", $$v)},expression:"form.billing_address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1835089723)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Address Line 2","label-for":"register-billing-address2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"billing_address2","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-billing-address2","name":"register-billing-address2","state":errors.length > 0 ? false:null,"placeholder":"Address Line 2"},model:{value:(_vm.form.billing_address2),callback:function ($$v) {_vm.$set(_vm.form, "billing_address2", $$v)},expression:"form.billing_address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,287199771)})],1)],1),_c('b-form-group',{attrs:{"label":"Country*","label-for":"register-billing-country","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"billing_country_id","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryList,"reduce":function (country_id) { return country_id.id; },"placeholder":"Select country","label":"name"},model:{value:(_vm.form.billing_country_id),callback:function ($$v) {_vm.$set(_vm.form, "billing_country_id", $$v)},expression:"form.billing_country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1138975148)})],1),_c('b-form-group',{attrs:{"label":"Region/State*","label-for":"register-billing-region","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Region/State","vid":"billing_state","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-billing-region","name":"register-billing-region","state":errors.length > 0 ? false:null,"placeholder":"Region/State"},model:{value:(_vm.form.billing_state),callback:function ($$v) {_vm.$set(_vm.form, "billing_state", $$v)},expression:"form.billing_state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2542038210)})],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Town/City*","label-for":"register-billing-city","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Town/City","vid":"billing_city","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-billing-city","name":"register-billing-city","state":errors.length > 0 ? false:null,"placeholder":"Town/City"},model:{value:(_vm.form.billing_city),callback:function ($$v) {_vm.$set(_vm.form, "billing_city", $$v)},expression:"form.billing_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,528514136)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Postcode/Zip Code*","label-for":"register-billing-postcode","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Postcode/Zip Code","vid":"billing_postcode","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-billing-postcode","name":"register-billing-postcode","state":errors.length > 0 ? false:null,"placeholder":"Postcode/Zip Code"},model:{value:(_vm.form.billing_postcode),callback:function ($$v) {_vm.$set(_vm.form, "billing_postcode", $$v)},expression:"form.billing_postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1870868500)})],1)],1)],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"isSameAddress","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.isSameAddress),callback:function ($$v) {_vm.isSameAddress=$$v},expression:"isSameAddress"}},[_vm._v(" Shipping address same as billing ")])],1),(!_vm.isSameAddress)?_c('p',[_vm._v(" Shipping address: ")]):_vm._e(),(!_vm.isSameAddress)?_c('div',{staticClass:"px-50"},[_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Address Line 1*","label-for":"register-shipping-address1","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"shipping_address1","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-shipping-address1","name":"register-shipping-address1","state":errors.length > 0 ? false:null,"placeholder":"Address Line 1"},model:{value:(_vm.form.shipping_address1),callback:function ($$v) {_vm.$set(_vm.form, "shipping_address1", $$v)},expression:"form.shipping_address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3869935426)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Address Line 2","label-for":"register-shipping-address2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"shipping_address1","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-shipping-address2","name":"register-shipping-address2","state":errors.length > 0 ? false:null,"placeholder":"Address Line 2"},model:{value:(_vm.form.shipping_address2),callback:function ($$v) {_vm.$set(_vm.form, "shipping_address2", $$v)},expression:"form.shipping_address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3239132290)})],1)],1),_c('b-form-group',{attrs:{"label":"Country*","label-for":"register-shipping-country","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"shipping_country_id","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryList,"reduce":function (country_id) { return country_id.id; },"placeholder":"Select country","label":"name"},model:{value:(_vm.form.shipping_country_id),callback:function ($$v) {_vm.$set(_vm.form, "shipping_country_id", $$v)},expression:"form.shipping_country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,432619381)})],1),_c('b-form-group',{attrs:{"label":"Region/State*","label-for":"register-shipping-region","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Region/State","vid":"shipping_state","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-shipping-region","name":"register-shipping-region","state":errors.length > 0 ? false:null,"placeholder":"Region/State"},model:{value:(_vm.form.shipping_state),callback:function ($$v) {_vm.$set(_vm.form, "shipping_state", $$v)},expression:"form.shipping_state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4180906555)})],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Town/City*","label-for":"register-shipping-city","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Town/City","vid":"shipping_city","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-shipping-city","name":"register-shipping-city","state":errors.length > 0 ? false:null,"placeholder":"Town/City"},model:{value:(_vm.form.shipping_city),callback:function ($$v) {_vm.$set(_vm.form, "shipping_city", $$v)},expression:"form.shipping_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3043299137)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Postcode/Zip Code*","label-for":"register-shipping-postcode","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Postcode/Zip Code","vid":"shipping_postcode","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-shipping-postcode","name":"register-shipping-postcode","state":errors.length > 0 ? false:null,"placeholder":"Postcode/Zip Code"},model:{value:(_vm.form.shipping_postcode),callback:function ($$v) {_vm.$set(_vm.form, "shipping_postcode", $$v)},expression:"form.shipping_postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2422718349)})],1)],1)],1):_vm._e()],1):_vm._e()])])],1),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isSavingActionInProgress,"block":"","type":"submit"}},[_vm._v(" Update company profile ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }